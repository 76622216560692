import React from "react";
import Layout from "../components/layout";
import UPIQRCode from "../assets/images/upi-qr-code.jpg";
import "../styles/pages/pay-online.scss";

export default function Downloads() {
  return (
    <Layout title="Pay Online">
      <h1 className="page-title">Pay Online</h1>
      <p>
        Now pay your membership fees and monthly fees directly to our
        association bank account via <strong>UPI, NEFT</strong> or{" "}
        <strong>IMPS</strong>.
      </p>
      <div className="account-details">
        <h3>UPI Account Details</h3>
        <div className="upi-wrapper">
          <div className="upi-scan">
            <img src={UPIQRCode} alt="nookampalaym0831@sbi" />
            <p>Scan this QR Code from your mobile</p>
          </div>
          <div className="upi-info">
            <p>
              UPI ID: <strong>nookampalaym0831@sbi</strong>
            </p>
            <p>
              Account Name:{" "}
              <strong>
                NOOKAMPALAYAM LINK ROAD RESIDENTS WELFARE ASSOCIATION
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className="account-details">
        <h3>Bank Account Details</h3>
        <table className="bank-details">
          <thead>
            <tr>
              <th>Field</th>
              <th>Bank Detail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bank Name</td>
              <td>
                <strong>State Bank of India, Sholinganallur</strong>
              </td>
            </tr>
            <tr>
              <td>Account Name</td>
              <td>
                <strong>
                  Nookampalyam Link Road Residents Welfare Association Semman
                </strong>
              </td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>
                <strong>41608290831</strong>
              </td>
            </tr>
            <tr>
              <td>IFSC Code</td>
              <td>
                <strong>SBIN0010525</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="payment-links">
        <ul>
          <li>
            <div className="payment-links-item">
              <div className="info">
                <p>
                  Registration Fees <br /> <span>One time</span>
                </p>
                <h2>₹500</h2>
              </div>
            </div>
          </li>
          <li>
            <div className="payment-links-item">
              <div className="info">
                <p>
                  One Year Membership Fees <br /> <span>Yearly</span>
                </p>
                <h2>₹600</h2>
              </div>
            </div>
          </li>
          <li>
            <div className="payment-links-item">
              <div className="info">
                <p>
                  One Month Membership Fees <br /> <span>Monthly</span>
                </p>
                <h2>₹50</h2>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Layout>
  );
}
